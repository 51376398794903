import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import { fontSize } from "styled-system"

import Container from "../components/Container"
import Heading from "../components/Heading"
import P from "../components/Paragraph"
import Box from "../components/Box"
import Grid from "../components/Grid"
import PageHeader from "../components/PageHeader"
import RatioContainer from "../components/RatioContainer"
import Link from "../components/Link"
import SeoHelmet from "../components/Seo"

const StyledLink = styled(Link)`
  text-decoration: none;
  ${fontSize};
  color: inherit;
  &:hover,
  &:focus {
    color: inherit;
  }
`

const CustomRatioContainer = styled(RatioContainer)`
  img {
    transition: transform 270ms ease;
    &:hover {
      transform: scale(1.07);
    }
  }
`

const BlogPage = () => {
  const blogData = useStaticQuery(graphql`
    query BlogQuery {
      allDatoCmsBlog(sort: { fields: position, order: ASC }) {
        nodes {
          id
          date(formatString: "MMMM D, YYYY")
          slug
          title
          cover {
            alt
            url
            title
          }
        }
      }
    }
  `)

  const posts = blogData.allDatoCmsBlog.nodes

  return (
    <>
      <SeoHelmet
        title="News"
        description="Our team shares updates from the fields of open data and data visualization."
        url={`http://lapidus.se/news`}
      />
      <PageHeader
        title="News and project updates"
        subtitle="Find out what our team is working on at the moment, as well as reflections on key trends and events."
      />
      <Container my="5rem">
        <Grid gridColumnGap={["1.5rem", "2.5rem"]}>
          {posts.map(({ id, slug, title, cover, date }) => (
            <Box
              key={id}
              gridColumn={["span 12", "span 6", "span 4"]}
              mb={["2.5rem", "5rem"]}
            >
              <CustomRatioContainer
                as={Link}
                to={`/news/${slug}`}
                ratio={2 / 3}
                style={{ overflow: "hidden", borderRadius: "0.75rem" }}
              >
                <img
                  src={
                    cover &&
                    cover.url + "?w=600&h=400&dpr=2&fit=crop&auto=format"
                  }
                  alt={cover && cover.alt}
                  width={600}
                  height={400}
                  style={{ width: "100%", height: "auto" }}
                />
              </CustomRatioContainer>
              <P
                fontSize="xs"
                my="0.75rem"
                fontWeight="600"
                color="darkGrey"
                style={{ textTransform: "uppercase" }}
              >
                {date}
              </P>
              <StyledLink to={`/news/${slug}`}>
                <Heading as="h3" fontSize={["md", "lg"]} my="0.75rem">
                  {title}
                </Heading>
              </StyledLink>

              <P
                fontSize={["xs", "sm"]}
                my="0"
                fontWeight="600"
                color="darkGrey"
                as={Link}
                to={`/news/${slug}`}
              >
                {"Read more"}
              </P>
            </Box>
          ))}
        </Grid>
      </Container>
    </>
  )
}

export default BlogPage

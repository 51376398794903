import React from "react"
import styled from "@emotion/styled"
import { space, color } from "styled-system"

const StyledRatioContainer = styled("div")`
  position: relative;
  height: 0;
  ${space}
`

const StyledRatioContent = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  ${color};
`

const RatioContainer = ({ ratio = 1 / 2, ...restProps }) =>
  <StyledRatioContainer pb={ratio * 100 + "%"}>
    <StyledRatioContent {...restProps} />
  </StyledRatioContainer>

export default RatioContainer